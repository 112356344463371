<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>账户管理</p>
			<i>/</i>
			<p @click="jumpPage">管理员列表</p>
			<i>/</i>
			<p class="active">管理员编辑</p>
		</nav>

		<div class="page_edit">

			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="cat_id">
						<p class="form_label_p">归属部门</p>
						<el-select class="input_long" v-model="ruleForm.cat_id" filterable placeholder="请选择归属部门"
							@change="departmentChange">
							<el-option v-for="item in departmentData" :key="item.id" :label="item.class_name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="role_id">
						<p class="form_label_p">角色</p>
						<el-select class="input_long" v-model="ruleForm.role_id" filterable placeholder="请选择角色">
							<el-option v-for="item in roleData" :key="item.id" :label="item.role_name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="phone">
						<p class="form_label_p">手机号</p>
						<el-input class="input_long" v-model="ruleForm.phone" maxlength="11" placeholder="请输入手机号">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="alias">
						<p class="form_label_p">昵称</p>
						<el-input class="input_long" v-model="ruleForm.alias" placeholder="请输入昵称">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="user_name">
						<p class="form_label_p">管理员名称</p>
						<el-input class="input_long" v-model="ruleForm.user_name" placeholder="请输入名称">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="email">
						<p class="form_label_p">邮箱</p>
						<el-input class="input_long" v-model="ruleForm.email" placeholder="请输入邮箱">
						</el-input>
					</el-form-item>
					<el-form-item label="">
						<p class="form_label_p">性别</p>
						<el-radio-group v-model="ruleForm.sex">
							<el-radio label="1">男</el-radio>
							<el-radio label="2">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="status">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.status">
							<el-radio label="1">激活</el-radio>
							<el-radio label="0">冻结</el-radio>
						</el-radio-group>
					</el-form-item>
					<div class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'accountEdit',
		data() {
			return {
				ruleForm: {
					cat_id: '', // 部门
					role_id: '', // 角色
					phone: '', // 手机号
					alias: '', // 昵称
					user_name: '', // 名称
					email: '', // 邮箱
					status: '1', // 状态默认启用
					sex: '', // 性别
				},
				rules: {
					cat_id: [{
						required: true,
						message: '请选择所属部门',
						trigger: 'change'
					}],
					role_id: [{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}],
					phone: [{
							required: true,
							message: '请填写手机号',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号错误'
						}
					],
					alias: [{
						required: true,
						message: '昵称不能为空',
						trigger: 'blur'
					}],
					user_name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					email: [{
						pattern: /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/,
						message: '邮箱错误'
					}],
				},
				modifyBtn: true, // 默认创建
				departmentData: [], // 部门数据
				roleData: [], // 角色列表数据
			}
		},
		components: {

		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				
				// 获取管理员详情做修改
				this.getAdminInfo();
			}
		},
		mounted() {
			// 获取部门列表
			this.getDepartmentList();
		},
		methods: {

			// 获取部门列表
			getDepartmentList() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/classlist`,
					data: {}
				}).then(res => {
					if (res.code == '0') {
						this.departmentData = res.data;
					}
				})
			},

			// 选择部门
			departmentChange(val) {
				this.ruleForm.role_id = '';
				this.getRoleList(val)
			},

			// 获取角色
			getRoleList(id) {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/rolelist`,
					data: {
						class_id: id,
					}
				}).then(res => {
					if (res.code == '0') {
						this.roleData = res.data;
					}
				})
			},
			
			// 获取管理员详情做修改
			getAdminInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/info`,
					data: {
						user_id: this.$route.query.id,
					}
				}).then(res => {
					if (res.code == '0') {
						this.ruleForm.cat_id = res.data.class_id;
						this.ruleForm.phone = res.data.mobile;
						this.ruleForm.alias = res.data.alias;
						this.ruleForm.user_name = res.data.user_name;
						this.ruleForm.sex = res.data.sex;
						this.ruleForm.email = res.data.email;
						this.ruleForm.status = res.data.status;
						
						this.getInfoRoleList(res.data.class_id, res.data.role_id);
					}
				})
			},
			
			// 获取管理员详情时获取角色
			getInfoRoleList(id, role_id) {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/rolelist`,
					data: {
						class_id: id,
					}
				}).then(res => {
					if (res.code == '0') {
						this.roleData = res.data;
						this.ruleForm.role_id = role_id;
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/add`,
							data: {
								class_id: this.ruleForm.cat_id,
								role_id: this.ruleForm.role_id,
								mobile: this.ruleForm.phone,
								alias: this.ruleForm.alias,
								user_name: this.ruleForm.user_name,
								sex: this.ruleForm.sex,
								email: this.ruleForm.email,
								status: this.ruleForm.status,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/edit`,
							data: {
								user_id: this.$route.query.id,
								class_id: this.ruleForm.cat_id,
								role_id: this.ruleForm.role_id,
								mobile: this.ruleForm.phone,
								alias: this.ruleForm.alias,
								user_name: this.ruleForm.user_name,
								sex: this.ruleForm.sex,
								email: this.ruleForm.email,
								status: this.ruleForm.status,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
</style>
